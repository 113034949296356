<template>
  <div class="slice-block">
    <div class="delete-icon-block">
      <uds-icon
        icon-name="delete"
        class="delete-icon"
        :color="getUdsColors().udsNeutral800"
        @click="deleteSlice()"
      />
    </div>
    <div class="slice-block-content">
      <uds-input-string
        label="Libellé"
        :value="designation"
        is-required
        enable-data-background
        @change="designation = $event ?? null"
      />

      <uds-input-amount
        label="Montant TTC (euros)"
        :value="amount"
        is-required
        @change="amount = $event || null"
      />

      <template v-if="!societyStore.isAccruals">
        <uds-input-select-popper
          label="TVA"
          placeholder="Choisir une TVA"
          :value="idTva"
          :items="filteredVats"
          item-value="idTva"
          item-text="name"
          :disabled="societyStore.isNotSubjectToVat || doesCategoryBlockVat"
          is-required
          @select="idTva = $event || null"
        />

        <CommonsInputsSimpleCategoryForm
          v-if="societyStore.isCashBased"
          :id-account="idAccount"
          required
          :has-credit-categories="idTypeCashdeskTransaction === CashDeskTransactionType.SALE"
          :has-debit-categories="idTypeCashdeskTransaction === CashDeskTransactionType.PURCHASE"
          :has-neutral-categories="true"
          :is-asset="false"
          @select="updateCategory($event)"
        />
      </template>
      <template v-else>
        <uds-input-select-auto-popper
          placeholder="Tiers"
          :value="idTiers"
          :items="formattedThirdParties"
          item-value="id"
          item-text="label"
          is-required
          @select="idTiers = $event || null"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { FormEntry, VatType } from "@silexpert/core";
import { Vat, CashDeskTransactionType } from "@silexpert/core";

const categoryStore = useCategoryStore();
const societyStore = useSocietyStore();
const thirdPartyStore = useThirdPartiesStore();

const props = defineProps<{
  slice: FormEntry;
  filteredVats: VatType[];
  idTypeCashdeskTransaction: number | null;
}>();

const emit = defineEmits<{
  (e: "updateSlice", data: { property: keyof FormEntry; value: any }): void;
  (e: "deleteSlice"): void;
}>();

const designation = computed({
  get() {
    return props.slice.designation ?? null;
  },
  set(newValue) {
    emit("updateSlice", { property: "designation", value: newValue });
  },
});

const idAccount = computed({
  get() {
    return props.slice.idAccount ?? null;
  },
  set(newValue) {
    emit("updateSlice", { property: "idAccount", value: newValue });
  },
});

const idTva = computed({
  get() {
    const firstEntry = props.slice;
    if (!isDefined(firstEntry)) {
      return null;
    }
    const hasPropertyAmountAllTaxIncludedPurchases = props.slice?.amountAllTaxIncludedPurchase;
    if (isDefined(hasPropertyAmountAllTaxIncludedPurchases)) {
      return Vat.MARGE.idTva;
    }

    const vatProperty = props.slice.vat;
    if (vatProperty) {
      if (isArray(vatProperty)) {
        if (vatProperty.length > 1) {
          return Vat.MANUAL.idTva;
        } else {
          // @ts-expect-error
          return vatProperty[0].idVat ?? null;
        }
      } else if (isNumber(vatProperty)) {
        return vatProperty;
      }
    }

    return null;
  },
  set(newValue) {
    emit("updateSlice", { property: "vat", value: newValue });
  },
});

const idTiers = computed({
  get() {
    return props.slice.idThirdParty ?? null;
  },
  set(newValue) {
    emit("updateSlice", { property: "idThirdParty", value: newValue });
  },
});

const amount = computed({
  get() {
    return props.slice.amountAllTaxIncluded ?? null;
  },
  set(newValue) {
    emit("updateSlice", { property: "amountAllTaxIncluded", value: newValue });
  },
});

function deleteSlice() {
  emit("deleteSlice");
}

const matchingCategory = computed(() =>
  idAccount.value ? (categoryStore.getMatchingCategory(idAccount.value) ?? null) : null,
);

const doesCategoryBlockVat = computed(() => matchingCategory.value?.blockVat ?? false);

function updateCategory(categoryId: number | boolean | null) {
  if (!(typeof categoryId === "boolean")) {
    idAccount.value = categoryId;
    const associatedVatId = matchingCategory.value?.idVat;
    if (isDefined(associatedVatId)) {
      idTva.value = societyStore.isNotSubjectToVat ? Vat.WITHOUT_TAXE.idTva : associatedVatId;
    }
  }
}
const formattedThirdParties = computed(() => {
  return thirdPartyStore.getFormattedThirdParties({
    generic: true,
    provider: true,
    customer: true,
  });
});
</script>

<style lang="scss" scoped>
.slice-block {
  background-color: rgba(224, 224, 224, 0.2);
  border-radius: 0.375rem;
  margin-top: $uds-spacing-2;
  padding: $uds-spacing-1;

  .delete-icon-block {
    display: flex;
    justify-content: flex-end;
    .delete-icon {
      cursor: pointer;
    }
  }
  .slice-block-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $uds-spacing-2;
  }
}
</style>
