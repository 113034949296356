<template>
  <CommonsModalsModalBody height="auto">
    <template #title>Importer vos opérations</template>

    <template #help>
      Importer votre relevé bancaire au format PDF.<br >
      Notre outil analyse puis intègre vos opérations dans votre compte bancaire.<br >
      Il est possible d'importer vos opérations au format CSV
      <div>
        <span class="model-link"
          ><a
            href="https://s3.eu-west-3.amazonaws.com/resources-clementine/sampleCSV/importTransactions.csv"
            download="example-import"
            >(Télécharger le modèle)</a
          ></span
        >
        <span>.</span><br >
        <span class="strong-text">
          Il est impératif de ne pas supprimer ou modifier l'ordre des colonnes<br >
        </span>
        <span>
          (1 500 lignes maximum).
          <br >
          <p/>
          <p>
            Le fichier doit garder le cartouche d'origine, les sommes comportant des centimes
            doivent être au format numérique (11.11) et la date au format suivant: DD-MM-YYYY
            <br >
          </p>
          <p>
            exemple:<br >
            VRT NUM;01-01-2019;11.11;0
          </p>
        </span>
      </div>
    </template>
    <template #content>
      <div class="uds-paragraph text-center">
        Uniquement si vous n’avez pas pu synchroniser tous vos comptes bancaires professionnels sur
        toute la période de votre comptabilité.
      </div>
      <br >
      <div class="form">
        <div class="form-row">
          <div class="col-max">
            <CommonsDropzoneContainer
              :extensions="basicImportMimeType"
              :max-files="maxFiles"
              :max-size="maxFilesize"
              :disabled="isLoading"
              style="width: 100%"
              :show-drop-box-button="true"
              @updated="form.files = $event"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="col-max">
            <uds-input-select-auto-popper
              label="Sélectionner un compte bancaire"
              :value="form.idBankAccount"
              :items="bankAccounts"
              item-value="id"
              item-text="name"
              :disabled="isDefined(form.newBankAccountName)"
              @select="form.idBankAccount = $event"
              @clear="form.idBankAccount = null"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="uds-paragraph" style="margin: auto">OU</div>
        </div>
        <div class="form-row">
          <div class="col-max">
            <uds-input-string
              label="Ajouter un nouveau compte"
              :value="form.newBankAccountName"
              :disabled="isDefined(form.idBankAccount) && form.idBankAccount !== ''"
              clearable
              @change="form.newBankAccountName = $event"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <uds-main-button type="tertiary" size="small" @click="$emit('cancel')">
        Annuler
      </uds-main-button>
      <uds-main-button size="small" :disabled="!isValid" :loading="isLoading" @click="create()"
        >Importer
      </uds-main-button>
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
import { BankAccountType } from "@silexpert/core";
import { basicImportMimeType } from "~/utils/file-type";

const bankAccountStore = useBankStore();
const societyStore = useSocietyStore();
const adjBankStore = useAdjustBankStatementStore();

const emit = defineEmits(["cancel", "close"]);

const form = ref({
  idBankAccount: null,
  newBankAccountName: null,
  files: [] as File[],
});

async function create() {
  isLoading.value = true;
  try {
    let idBankAccount: number | null = form.value.idBankAccount;
    if (isDefined(form.value.newBankAccountName)) {
      const bankAccount = await $silex()
        .bankAccount.create(societyStore.society!.id!, {
          name: form.value.newBankAccountName,
          idBankAccountType: BankAccountType.BANK,
        })
        .then((ba) => {
          bankAccountStore.fetchBankAccounts(societyStore.society!.id!);
          return ba;
        });
      idBankAccount = bankAccount.id!;
    }

    $silex().bankStatement.upload(form.value.files[0], {
      idSociety: societyStore.society!.id!,
      idBankAccount: idBankAccount as number,
    });
    isLoading.value = false;

    adjBankStore.bankAccountSelectedForExtraction = idBankAccount ?? -1;
    adjBankStore.shouldDisplayThatImportIsPending = true;

    emit("close");
  } catch (error) {
    $notifier().open({ content: apiErrorToString(error) });
  }
  isLoading.value = false;
}

const maxFiles = 1;
const maxFilesize: number = 2; // in MB
const isLoading = ref(false);

const bankAccounts = computed(() =>
  bankAccountStore.activatedBankAccounts
    .filter((b) => b.typeBankAccount === BankAccountType.BANK)
    .sort((a, b) => b.id! - a.id!),
);

const isValid = computed(() => {
  return (
    (isDefined(form.value.idBankAccount) || isDefined(form.value.newBankAccountName)) &&
    form.value.files.length
  );
});
</script>

<style lang="scss" scoped>
.model-link a {
  color: $uds-primary-500 !important;
  text-decoration: none;
}
</style>
