<template>
  <CommonsModalsModalBody :svg="isComptalib() ? 'ComptalibCashSvg' : 'CashSvg'" height="auto">
    <template #title> Déclarer une opération en espèces </template>

    <template #help>
      Déclarer des espèces permet d'enregistrer une recette ou une dépense en liquide pour que
      celle-ci puisse être comptabilisée et ainsi faire fonctionner le processus comptable.
    </template>

    <template #content>
      <CommonsDropzoneContainer
        v-if="societyStore.isCashBased"
        :extensions="basicImageAndPdfMimeType"
        :max-files="1"
        :max-size="1.9"
        :disabled="isLoading"
        :show-drop-box-button="true"
        @updated="form.files = $event"
      />

      <div class="modal-content">
        <div class="col">
          <uds-input-calendar
            label="Date"
            :date="form.dateValue"
            enable-data-background
            is-required
            @change-date="form.dateValue = $event"
          />
          <uds-input-select-auto-popper
            label="Type d'opération"
            :value="idTypeCashdeskTransaction"
            :items="cashDeskTransactionType"
            item-value="idTypeCashdeskTransaction"
            item-text="name"
            is-required
            :error="errors.idTypeCashdeskTransaction"
            @select="idTypeCashdeskTransaction = $event || null"
          />
        </div>

        <template v-if="slices.length < 2">
          <div class="col">
            <uds-input-amount
              label="Montant en €"
              :value="form.amountAllTaxIncluded"
              :error="errors.amountAllTaxIncluded"
              is-required
              @change="form.amountAllTaxIncluded = $event || null"
            />
            <uds-input-select-popper
              v-if="societyStore.isCashBased && !societyStore.isNotSubjectToVat"
              label="TVA"
              placeholder="Choisir une TVA"
              :value="form.vat"
              :items="filteredVats"
              item-value="idTva"
              item-text="name"
              is-required
              :error="errors.idVat"
              :disabled="shouldDisableVat"
              @select="form.vat = $event || null"
            />
            <uds-input-select-custom
              v-if="societyStore.isAccruals"
              label="Tiers"
              :value="thirdPartyText"
              :is-required="!isCategoryOrThirdPartyDisabled"
              :enable-data-background="!!thirdPartyText"
              :disabled="isCategoryOrThirdPartyDisabled"
              clearable
              @clear="(form.idThirdParty = null), (form.idAccount = null)"
            >
              <template #inputContent>
                <span v-if="thirdPartyText" class="truncate-text">{{ thirdPartyText }}</span>
                <span v-else class="placeholder">Choisir le tiers</span>
              </template>
              <template #dropdownContent="{ hide }">
                <CommonsDropdownsThirdPartyDropdown
                  v-if="societyStore.isAccruals"
                  :has-generics="true"
                  :has-providers="true"
                  :has-customers="true"
                  :is-credit="!form.amountAllTaxIncluded || form.amountAllTaxIncluded > 0"
                  :ids-third-party-already-selected="{
                    idTier: form.idThirdParty,
                    idAccount: form.idAccount,
                  }"
                  :error="errors.idAccount"
                  @select="(form.idThirdParty = $event.idTier || null), hide()"
                />
              </template>
            </uds-input-select-custom>
          </div>

          <CommonsInputsSimpleCategoryForm
            v-if="societyStore.isCashBased"
            :id-account="form.idAccount"
            :has-credit-categories="form.idTypeCashdeskTransaction === CashDeskTransactionType.SALE"
            :has-debit-categories="
              form.idTypeCashdeskTransaction === CashDeskTransactionType.PURCHASE
            "
            :has-neutral-categories="true"
            :is-asset="false"
            :required="!isCategoryOrThirdPartyDisabled"
            :disabled="isCategoryOrThirdPartyDisabled"
            @select="updateCategory($event)"
          />
        </template>

        <template v-else>
          <CommonsModalsBanksNewCashDeclarationSlice
            v-for="(slice, index) in slices"
            :key="`${slices[index + 1]}`"
            :slice="slice"
            :filtered-vats="filteredVats"
            :id-type-cashdesk-transaction="idTypeCashdeskTransaction"
            @update-slice="updateSlice($event, index)"
            @delete-slice="deleteSlice(index)"
          />
        </template>
      </div>
    </template>

    <template #footer>
      <uds-main-button type="tertiary" size="small" @click="$emit('cancel')">
        Annuler
      </uds-main-button>
      <template v-if="canBeSliced">
        <template v-if="slices.length === 0">
          <uds-main-button type="secondary" size="small" @click="startSlices"
            >Diviser</uds-main-button
          >
        </template>
        <template v-else>
          <uds-main-button size="small" @click="addSlice">Ajouter une division</uds-main-button>
        </template>
      </template>
      <uds-main-button
        size="small"
        :disabled="!isFormValid"
        :loading="isLoading"
        :class="{ 'ml-2': canBeSliced }"
        @click="createCashTransaction()"
        >Enregistrer</uds-main-button
      >
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
import type { CreateCashAndExpenseTransaction, FormEntry, VatType } from "@silexpert/core";
import { Account, AccountingJournal, CashDeskTransactionType, Vat } from "@silexpert/core";

const RETRAIT_DE_LIQUIDE_ID_TIERS = 1;
const DEPOT_DE_LIQUIDE_ID_TIERS = 2;

const { isComptalib } = useBrandsComposable();

type SliceWithId = FormEntry & {
  id: number;
};

type AccrualsCashTransactionPayload = {
  dateValue: string;
  amountAllTaxIncluded: number;
  idThirdParty: number;
  idTypeCashdeskTransaction: number;
};

type CashBasedCashTransactionPayload = {
  files?: File[];
  dateValue: string; // 2021-01-29
  amountAllTaxIncluded: number;
  vat: number | { idVat: number; amount: number }[];
  idAccount: number;
  idTypeCashdeskTransaction: number;
};

type CashTransactionPayload = AccrualsCashTransactionPayload & CashBasedCashTransactionPayload;
type CashTransactionForm = {
  [key in keyof CashTransactionPayload]-?: CashTransactionPayload[key] | null;
};

const societyStore = useSocietyStore();
const categoryStore = useCategoryStore();
const thirdPartyStore = useThirdPartiesStore();
const bankStore = useBankStore();

const { getVatsForAnnotating } = useAnnotationUtilsComposable();

const dayjs = useDayjs();

const emit = defineEmits(["cancel", "close"]);

const form = ref<CashTransactionForm>({
  files: [],
  dateValue: dayjs().format("YYYY-MM-DD"),
  amountAllTaxIncluded: null,
  vat: societyStore.isNotSubjectToVat ? Vat.WITHOUT_TAXE.idTva : null,
  idAccount: null,
  idThirdParty: null,
  idTypeCashdeskTransaction: null,
});

const matchingThirdParty = computed(() =>
  thirdPartyStore.getMatchingThirdParty({
    idThirdParty: form.value.idThirdParty ?? null,
    idAccount: null,
  }),
);

const thirdPartyText = computed<string>(() => matchingThirdParty.value?.label ?? "");

const isCategoryOrThirdPartyDisabled = computed<boolean>(
  () =>
    !isDefined(form.value.idTypeCashdeskTransaction) ||
    [CashDeskTransactionType.CASH_WITHDRAWAL, CashDeskTransactionType.CASH_DEPOSIT].includes(
      form.value.idTypeCashdeskTransaction,
    ),
);

const shouldDisableVat = computed<boolean>(
  () =>
    !isDefined(form.value.idTypeCashdeskTransaction) ||
    [CashDeskTransactionType.CASH_WITHDRAWAL, CashDeskTransactionType.CASH_DEPOSIT].includes(
      form.value.idTypeCashdeskTransaction,
    ) ||
    doesCategoryBlockVat.value,
);

const slices = ref<FormEntry[]>([]);
const isLoading = ref(false);
const slicesCreated = ref(0);

const cashDeskTransactionType = computed(() => {
  return [
    {
      idTypeCashdeskTransaction: CashDeskTransactionType.CASH_WITHDRAWAL,
      name: "Retrait d'espèces",
    },
    {
      idTypeCashdeskTransaction: CashDeskTransactionType.CASH_DEPOSIT,
      name: "Ajout d'espèces",
    },
    {
      idTypeCashdeskTransaction: CashDeskTransactionType.SALE,
      name: "Vente",
    },
    {
      idTypeCashdeskTransaction: CashDeskTransactionType.PURCHASE,
      name: "Achat",
    },
  ];
});

const errors = computed<Record<string, string | null>>(() => ({
  idTypeCashdeskTransaction: !isDefined(form.value.idTypeCashdeskTransaction)
    ? "Veuillez remplir le champ"
    : null,
  dateValue: !isDefined(form.value.dateValue) ? "Veuillez renseigner une date" : null,
  amountAllTaxIncluded: !isDefined(form.value.amountAllTaxIncluded)
    ? "Veuillez renseigner un montant"
    : null,
  idVat:
    !isDefined(form.value.vat) && !societyStore.isAccruals && !societyStore.isNotSubjectToVat
      ? "Veuillez renseigner la tva"
      : null,
  idAccount:
    !isDefined(form.value.idAccount) && !societyStore.isAccruals
      ? "Veuillez renseigner la catégorie"
      : null,
  idThirdParty:
    !isDefined(form.value.idThirdParty) && societyStore.isAccruals
      ? "Veuillez renseigner le tiers"
      : null,
}));

const hasSlicesErr = computed(() => {
  const slicesErrors =
    slices.value.filter((s: FormEntry) => {
      return (
        !isDefined(s.designation) ||
        (!isDefined(s.vat) && !societyStore.isAccruals && !societyStore.isNotSubjectToVat) ||
        !isDefined(s.amountAllTaxIncluded) ||
        (!isDefined(s.idAccount) && !societyStore.isAccruals) ||
        (!isDefined(s.idThirdParty) && societyStore.isAccruals)
      );
    }) ?? [];

  return slicesErrors.length > 0;
});

const isFormValid = computed(() => {
  return slices.value.length > 1
    ? !hasSlicesErr.value &&
        !isDefined(errors.value.idTypeCashdeskTransaction) &&
        !isDefined(errors.value.dateValue)
    : Object.values(errors.value).every((error) => !isDefined(error));
});

const idTypeCashdeskTransaction = computed({
  get() {
    return form.value.idTypeCashdeskTransaction;
  },
  set(newValue) {
    if (newValue === CashDeskTransactionType.SALE && form.value.vat) {
      form.value.vat = null;
    }

    const oldValue = form.value.idTypeCashdeskTransaction;
    if (newValue === CashDeskTransactionType.CASH_WITHDRAWAL) {
      form.value.idAccount = Account.CASH_WITHDRAWAL.id;
      form.value.vat = Vat.WITHOUT_TAXE.idTva;
      form.value.idThirdParty = RETRAIT_DE_LIQUIDE_ID_TIERS;
      slices.value = [];
    } else if (newValue === CashDeskTransactionType.CASH_DEPOSIT) {
      form.value.idAccount = Account.CASH_DEPOSIT.id;
      form.value.vat = Vat.WITHOUT_TAXE.idTva;
      form.value.idThirdParty = DEPOT_DE_LIQUIDE_ID_TIERS;
      slices.value = [];
    } else if (
      oldValue === CashDeskTransactionType.CASH_WITHDRAWAL ||
      oldValue === CashDeskTransactionType.CASH_DEPOSIT
    ) {
      // if the user goes from a "Retrait d’espèces / Ajout d’espèces" to the other types,
      // we suppose the prefilled category and tva are outdated, so we remove them
      form.value.idAccount = null;
      form.value.idThirdParty = null;
      removeCategories();
      removeThirdParties();
    } else {
      // remove categories since they are depedent on the idTypeCashdeskTransaction
      form.value.idThirdParty = null;
      removeCategories();
      removeThirdParties();
    }
    form.value.idTypeCashdeskTransaction = newValue;
  },
});

const filteredVats = computed<VatType[]>(() => {
  return getVatsForAnnotating({
    intracom: form.value.idTypeCashdeskTransaction === CashDeskTransactionType.SALE,
    margin: false,
    manual: false,
  });
});

const matchingCategory = computed(() =>
  form.value.idAccount ? (categoryStore.getMatchingCategory(form.value.idAccount) ?? null) : null,
);

const doesCategoryBlockVat = computed<boolean>(() => matchingCategory.value?.blockVat ?? false);

async function createCashTransaction() {
  isLoading.value = true;

  if (!isFormValid.value) {
    isLoading.value = false;
    $notifier().open({
      type: "error",
      content: "Veuillez renseigner tous les champs obligatoires",
    });
    return;
  }

  const {
    dateValue,
    idTypeCashdeskTransaction,
    idThirdParty,
    vat,
    idAccount,
    amountAllTaxIncluded,
    files,
  } = form.value;

  const entries =
    slices.value.length > 0
      ? slices.value
      : [
          {
            ...(idThirdParty && societyStore.isAccruals ? { idThirdParty } : {}),
            ...(!societyStore.isAccruals
              ? {
                  ...(vat ? { vat } : {}),
                  ...(idAccount ? { idAccount } : {}),
                }
              : {}),
            amountAllTaxIncluded: amountAllTaxIncluded ?? undefined,
          },
        ];

  const payload2: CreateCashAndExpenseTransaction = {
    dateValue: dayjs(dateValue).format("YYYY-MM-DD"),
    cashDeskTransactionType: idTypeCashdeskTransaction!,
    // @ts-expect-error
    files: files!,
    idAccountingJournal: AccountingJournal.CAI,
    entries,
  };
  try {
    await $silex().cashAndExpense.createTransaction(payload2);
    const bankLink = useBuildRouteFromQueryPropertiesComposable(
      defaultBankQueryProperties.value,
      {
        ...bankStore.queryProperties,
        tab: "cash",
        idBankAccount: [],
        toAnnotate: false,
        transactionId: null,
      },
      "/banks",
    );
    await navigateTo(`${bankLink}#forceRefresh`);
    $notifier().open({ type: "success", content: "Opération en espèces ajoutée" });
    emit("close");
  } catch (error) {
    $notifier().open({
      type: "error",
      content: apiErrorToString(error),
    });
  } finally {
    isLoading.value = false;
  }
}

const canBeSliced = computed(() => {
  return (
    form.value.idTypeCashdeskTransaction !== CashDeskTransactionType.CASH_DEPOSIT &&
    form.value.idTypeCashdeskTransaction !== CashDeskTransactionType.CASH_WITHDRAWAL
  );
});
function startSlices() {
  const firstSlice = {
    ...createDefaultSlice(),
    vat: form.value.vat ?? undefined,
    idAccount: form.value.idAccount ?? undefined,
    amountAllTaxIncluded: form.value.amountAllTaxIncluded ?? undefined,
  };

  slices.value = [firstSlice];
  slices.value.push(createDefaultSlice());
}
function addSlice() {
  slices.value.push(createDefaultSlice());
}
function createDefaultSlice(): SliceWithId {
  slicesCreated.value += 1;

  // @ts-expect-error
  const defaultSlice = {
    designation: "Mvt d'espèces",
    idAccount: undefined,
    vat: undefined,
    idThirdParty: undefined,
    amountAllTaxIncluded: undefined,
    id: slicesCreated.value,
    ...(societyStore.isNotSubjectToVat && { vat: Vat.WITHOUT_TAXE.idTva }),
  };
  return defaultSlice;
}
function deleteSlice(index: number) {
  slices.value.splice(index, 1);

  if (slices.value.length === 1) {
    const lastSlice = slices.value[0];
    form.value.vat = lastSlice.vat ?? null;
    form.value.idAccount = lastSlice.idAccount ?? null;
    form.value.amountAllTaxIncluded = lastSlice.amountAllTaxIncluded ?? null;
    slices.value = [];
  }
}
function updateSlice<P extends keyof FormEntry>(
  update: { property: P; value: FormEntry[P] },
  index: number,
) {
  slices.value[index][update.property] = update.value;
}

function removeCategories() {
  form.value.idAccount = null;
  for (const slice of slices.value) {
    slice.idAccount = undefined;
  }
}

function removeThirdParties() {
  form.value.idThirdParty = null;
  for (const slice of slices.value) {
    slice.idThirdParty = undefined;
  }
}
function updateCategory(categoryId: number | boolean | null) {
  if (!(typeof categoryId === "boolean")) {
    form.value.idAccount = categoryId;
    const associatedVatId = matchingCategory.value?.idVat;
    if (isDefined(associatedVatId)) {
      form.value.vat = societyStore.isNotSubjectToVat ? Vat.WITHOUT_TAXE.idTva : associatedVatId;
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-content {
  margin-bottom: $uds-spacing-4;
  margin-top: $uds-spacing-2;

  .col {
    display: grid;
    gap: $uds-spacing-2;
    grid-template-columns: 1fr 1fr;
    margin-bottom: $uds-spacing-2;
  }
}
</style>
