<template>
  <uds-input-select-custom
    label="Catégorie"
    :is-required="required === true"
    :disabled="disabled === true"
    :clearable="required !== true"
    :value="idAccount"
    @clear="emits('select', null)"
  >
    <template #inputContent>
      <span v-if="categoryText">{{ categoryText }}</span>
      <span v-else class="placeholder">Choisir la catégorie</span>
    </template>
    <template #dropdownContent="{ hide }">
      <CommonsDropdownsCategoryDropdown
        :has-credit-categories="hasCreditCategories"
        :has-neutral-categories="hasNeutralCategories"
        :has-debit-categories="hasDebitCategories"
        :is-asset="isAsset"
        :has-is-asset="false"
        @select="emits('select', $event ?? null), hide()"
        @close="hide()"
      />
    </template>
  </uds-input-select-custom>
</template>

<script setup lang="ts">
const categoryStore = useCategoryStore();

const props = defineProps<{
  idAccount: number | null | undefined;
  hasCreditCategories: boolean;
  hasNeutralCategories: boolean;
  hasDebitCategories: boolean;
  disabled?: boolean;
  required?: boolean;
  isAsset: boolean;
}>();

const emits = defineEmits<{
  (e: "select", idAccount: number | boolean | null): void;
}>();

const categoryText = computed<string | null>(() =>
  props.idAccount
    ? (categoryStore.categories ?? []).find((cat) => cat.id === props.idAccount)?.name ?? null
    : null,
);
</script>
